export default [
  {
    name: "mySelfContract",
    path: "/mySelfContract",
    component: () => import("@/views/contract/mySelfContract.vue"),
    meta: {
      keepAlive: true,
      title: "我的上传合同管理"
    }
  },
  {
    name: "adminContract",
    path: "/adminContract",
    component: () => import("@/views/contract/adminContract.vue"),
    meta: {
      keepAlive: true,
      title: "管理员合同管理"
    }
  },
  {
    name: "userContract",
    path: "/userContract",
    component: () => import("@/views/contract/userContract.vue"),
    meta: {
      keepAlive: true,
      title: "用户合同管理"
    }
  },
  {
    name: "classificationEdit",
    path: "/classificationEdit",
    component: () => import("@/views/contract/classificationEdit.vue"),
    meta: {
      title: "合同详情"
    }
  }
];
