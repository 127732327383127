<template>
  <div class="popup-wrap">
    <div class="popup-title">
      {{ title }}
    </div>
    <div class="popup-main">
      <div class="list">
        <van-list finished-text="没有更多了">
          <van-cell clickable
                    @click="index=ind"
                    v-for="(option,ind) in columns"
                    :key="option.id">
            <template #title>
              <span v-if="!$scopedSlots.option"> {{option[valueKey]}}</span>
              <slot v-if="$scopedSlots.option"
                    name='option'
                    :option="option"> </slot>
            </template>
            <template #right-icon
                      v-if="selectIcon">
              <van-icon name="success"
                        class="search-icon"
                        v-if="index==ind" />
            </template>
          </van-cell>

        </van-list>

        <van-list finished-text="没有更多了"
                  v-if="isChilden">
          <van-cell clickable
                    @click="childIndex=childInd"
                    v-for="(option,childInd) in columns[index].children"
                    :key="option.id">
            <template #title>
              <span v-if="!$scopedSlots.option"> {{option[valueKey]}}</span>
              <slot v-if="$scopedSlots.option"
                    name='option'
                    :option="option"> </slot>
            </template>
            <template #right-icon
                      v-if="selectIcon">
              <van-icon name="success"
                        class="search-icon"
                        v-if="childIndex==childInd" />
            </template>
          </van-cell>
        </van-list>
      </div>

    </div>
    <div class="foot-btn">
      <van-button type="default"
                  class="button"
                  color="#A6A9AD"
                  round
                  size="small"
                  native-type="button"
                  @click="cancel">取消</van-button>
      <van-button type="info"
                  round
                  class="button"
                  size="small"
                  :disabled="!columns.length"
                  native-type="button"
                  @click.stop="confirm">确定</van-button>
    </div>
  </div>
</template>

<script>
import "@/assets/less/components/customPopup.less";
export default {
  name: "customPopup",
  props: {
    title: {
      type: String,
      default: "default title",
      required: true
    },
    columns: {
      type: Array,
      default: []
    },
    valueKey: {
      type: String,
      default: "text"
    },
    selectIcon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      index: 0,
      childIndex: 0
    };
  },
  created() {
    console.log(123, this.$scopedSlots);
  },
  computed: {
    isChilden() {
      let isFlag;
      try {
        isFlag = this.columns[this.index].children && this.columns[this.index].children.length;
      } catch {
        isFlag = false;
      }
      return isFlag;
    }
  },
  watch: {
    index(e) {
      this.childIndex = 0;
    },
    columns() {
      this.index = 0;
    }
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    setOption(par) {
      return { id: 1, name: 2 };
    },
    confirm() {
      let item = this.columns[this.index];
      if (item.children && item.children.length) {
        return this.$emit("confirm", item.children[this.childIndex]);
      }
      this.$emit("confirm", item);
    }
  }
};
</script>
