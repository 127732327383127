export default [
  {
    path: "/commodityList",
    name: "commodityList",
    component: () => import("../../views/commodity/commodityList"),
    meta: {
      keepAlive: true,
      title: "物品管理"
    }
  },
  {
    path: "/addCommodity",
    name: "addCommodity",
    component: () => import("../../views/commodity/commodityDetail"),
    meta: {
      title: "新增物品"
    }
  },
  {
    path: "/commodityDetail/:id",
    name: "commodityDetail",
    component: () => import("../../views/commodity/commodityDetail"),
    meta: {
      title: "物品详情"
    }
  },
  {
    path: "/commodityWarehousingList",
    name: "commodityWarehousingList",
    component: () => import("../../views/commodity/commodityWarehousingList"),
    meta: {
      title: "物品入库管理"
    }
  },
  {
    path: "/addCommodityWarehousing",
    name: "addCommodityWarehousing",
    component: () => import("../../views/commodity/commodityWarehousingDetail"),
    meta: {
      title: "新增入库"
    }
  },
  {
    path: "/commodityWarehousingDetail/:id",
    name: "commodityWarehousingDetail",
    component: () => import("../../views/commodity/commodityWarehousingDetail"),
    meta: {
      title: "入库详情"
    }
  },
  {
    path: "/commodityOutOfStocklist",
    name: "commodityOutOfStocklist",
    component: () => import("../../views/commodity/commodityOutOfStockList"),
    meta: {
      keepAlive: true,
      title: "物品出库管理"
    }
  },
  {
    path: "/addCommodityOutOfStock",
    name: "addCommodityOutOfStock",
    component: () => import("../../views/commodity/commodityOutOfStockDetail"),
    meta: {
      title: "新增出库"
    }
  },
  {
    path: "/commodityOutOfStockDetail/:id",
    name: "commodityOutOfStockDetail",
    component: () => import("../../views/commodity/commodityOutOfStockDetail"),
    meta: {
      title: "物品出库详情"
    }
  },
  {
    path: "/commodityInventoryList",
    name: "commodityInventoryList",
    component: () => import("../../views/commodity/commodityInventoryList"),
    meta: {
      keepAlive: true,
      title: "物品库存"
    }
  },
  {
    path: "/commodityInventory",
    name: "commodityInventory",
    component: () => import("../../views/commodity/commodityInventory"),
    meta: {
      title: "物品盘点"
    }
  },
  {
    path: "/commodityApplyOrderList",
    name: "commodityApplyOrderList",
    component: () => import("../../views/commodity/commodityApplyOrderList"),
    meta: {
      keepAlive: true,
      title: "物品请领管理"
    }
  },
  {
    path: "/commodityApplyRecords",
    name: "commodityApplyRecords",
    component: () => import("../../views/commodity/commodityApplyRecords"),
    meta: {
      keepAlive: true,
      title: "物品请领记录"
    }
  },
  {
    path: "/commodityPickingDetail/:id",
    name: "commodityPickingDetail",
    component: () => import("../../views/commodity/commodityPickingDetail"),
    meta: {
      title: "物品拣货"
    }
  },
  {
    name: "warehouseManage",
    path: "/warehouseManage",
    component: () => import("@/views/warehouse/warehouseManage.vue"),
    meta: {
      title: "仓库管理"
    }
  },
  {
    name: "warehouseStockSiteManage",
    path: "/warehouseStockSiteManage/:id",
    component: () => import("@/views/warehouse/warehouseStockSiteManage.vue"),
    meta: {
      title: "货位管理"
    }
  }
];
