import Vue from "vue";
import App from "./Main.vue";
import router from "@/router/index.js";
import store from "@/vuex";
import component from "@/components/index.js";
import "normalize.css";
import $utils from "@/utils/fn.js";
import $serviceUtils from "@/utils/serviceUtils.js";
import filter from "@/utils/filter.js";

import Vant from "vant";
import "vant/lib/index.less";
// import "@/assets/less/vant-overide.less";
import "@/assets/less/index.less";

import "@/utils/flexible.js";

import { apis } from "@/apis";
import Keycloak from "keycloak-js";

import brandConfig from "@/config/index.js";
Vue.use(require("vue-moment"));

Vue.use(Vant);

Vue.use(component);

Vue.use(filter);

// api
Vue.prototype.$api = apis;

Vue.prototype.$utils = $utils;

Vue.prototype.$serviceUtils = $serviceUtils;
Vue.prototype.$commonBus = new Vue();
Vue.prototype.$brand = brandConfig[process.env.VUE_APP_REALM] || [];

let realm = localStorage.getItem("realm");
// 没有从url里面拿
if (!realm) {
  let search = decodeURIComponent(window.location.search);
  let result = search.match(/redirect_uri=(.+)&state=/);
  if (result && result[1]) realm = result[1];
}

// 没有默认为 smart-home
// if (!realm) {
Vue.prototype.$realm = realm = process.env.VUE_APP_REALM;
// "smarthome-template";
// ;
// }

const initOptions = {
  url: process.env.VUE_APP_KEYCLOAK_HOST,
  realm: realm,
  clientId: "vue-admin-page",
  onLoad: "login-required"
};

const keycloak = Keycloak(initOptions);

keycloak
  .init({ onLoad: initOptions.onLoad, promiseType: "native" })
  .then(authenticated => {
    if (!authenticated) {
      window.location.reload();
    } else {
      Vue.prototype.$keycloak = keycloak;
      window.keycloak = keycloak;
      store.state.userInfo.preferred_username = keycloak.tokenParsed.preferred_username;

      store.state.userInfo.userName = keycloak.tokenParsed.given_name || "";
      store.state.userInfo.userId = keycloak.tokenParsed.uid;

      $utils.setSession(["token", "expireAt"], [keycloak.token, keycloak.tokenParsed.exp + keycloak.timeSkew]);
      console.log("Authenticated", Vue.prototype.$keycloak);
    }

    function timeRefresh() {
      setTimeout(() => {
        console.log("update token!");
        keycloak
          .updateToken(-1)
          .then(refreshed => {
            console.log("refreshed token", refreshed);

            $utils.setSession(["token", "expireAt"], [keycloak.token, keycloak.tokenParsed.exp + keycloak.timeSkew]);
          })
          .catch(e => {
            console.log(e);
          });

        timeRefresh();
      }, 5 * 60 * 1000);
    }
    timeRefresh();

    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#main");
  })
  .catch(error => {
    console.log("Authenticated Failed", error);
  });

/* new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#main"); */
