export default [
  {
    path: "/assetsAccount",
    name: "assetsAccount",
    component: () => import("../../views/assets/account.vue"),
    meta: {
      keepAlive: true,
      title: "资产账"
    }
  },
  {
    path: "/accountEdit",
    name: "accountEdit",
    component: () => import("../../views/assets/accountEdit.vue"),
    meta: {
      title: "资产详情"
    }
  },
  {
    path: "/borrow",
    name: "borrow",
    component: () => import("../../views/assets/borrow.vue"),
    meta: {
      title: "资产借用列表"
    }
  },
  {
    path: "/borrowEdit",
    name: "borrowEdit",
    component: () => import("../../views/assets/borrowEdit.vue"),
    meta: {
      title: "资产借用新增"
    }
  },
  {
    path: "/requisition",
    name: "requisition",
    component: () => import("../../views/assets/requisition.vue"),
    meta: {
      title: "资产领用列表"
    }
  },
  {
    path: "/requisitionEdit",
    name: "requisitionEdit",
    component: () => import("../../views/assets/requisitionEdit.vue"),
    meta: {
      title: "资产领用新增"
    }
  },
  {
    path: "/outboundOrder",
    name: "outboundOrder",
    component: () => import("../../views/assets/outboundOrder.vue"),
    meta: {
      keepAlive: true,
      title: "资产出库单列表"
    }
  },
  {
    path: "/picking",
    name: "picking",
    component: () => import("../../views/assets/picking.vue"),
    meta: {
      title: "资产借用出库单信息"
    }
  },
  {
    path: "/assetsRevertList",
    name: "assetsRevertList",
    component: () => import("../../views/assets/assetsRevertList.vue"),
    meta: {
      keepAlive: true,
      title: "资产归还列表"
    }
  },
  {
    path: "/assetsRevertDetail/:id",
    name: "assetsRevertDetail",
    component: () => import("../../views/assets/assetsRevertDetail.vue"),
    meta: {
      title: "资产归还详情"
    }
  }
];
