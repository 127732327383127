<template>
  <div class="content">
    <h1>您好!{{ userInfo.userName }}</h1>

    <!-- 菜单模块 -->
    <div class="workstation-modules van-hairline--surround">
      <div v-for="item in myMenuModules"
           :key="item.name"
           style="margin-top: 0.5rem;">
        <h3>{{ item.name }}</h3>
        <div class="sub-menus">
          <div v-for="menu in item.menus"
               class="menu-item"
               :key="menu.name"
               @click="routerToMenu(menu)">
            <i class="iconfont"
               :class="menu.img"></i>
            <div class="menu-name">
              {{ menu.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { SUCCESS_CODE } from "../../apis/notification.js";
import wechat from "@/lib/wechat.js";
// let prefix = "/imgs/iconfont/";
let img = {
  "/assetsAccount": "icon-zichanguanli",
  "/outboundOrder": "icon-chuku",
  "/borrow": "icon-zichanjieyong",
  "/requisition": "icon-zichanlingyong",
  "/assetsRevertList": "icon-wupinlingyong",
  "/appointment": "icon-wodeyuyue",
  "/classroomList": "icon-ico_jiudianguanli_fangjianyuyue",
  "/classroomRevert": "icon-fangjianweihu",
  "/warehouseManage": "icon-kucunguanli",
  "/commodityType": "icon-mulu",
  "/commodityList": "icon-mulu",
  "/commodityWarehousingList": "icon-ruku",
  "/commodityInventoryList": "icon-kucunguanli",
  "/commodityApplyRecords": "icon-ico_kufangguanli_wupinqingling",
  "/commodityOutOfStockList": "icon-chuku",
  "/commodityApplyOrderList": "icon-ico_kufangguanli_qinglingchuku",
  "/approvalByMe": "icon-wodeshenpi",
  "/approvalByMeHistory": "icon-wodeshenpi",
  "/applyByMe": "icon-wodeshenqing",
  "form:assetsLending": "icon-zichanjieyong",
  "form:assetsPickup": "icon-zichanlingyong",
  "form:itemApply": "icon-wupinlingyong",
  "/adminContract": "icon-hetongguanli",
  "/classroomRevert/user": "icon-jiaoshi02",
  "/classroom": "icon-jiaoshijiaoshiguanli",
  "/userAppointment": "icon-wodeyuyue",
  "/classroomStateList": "icon-zhuangtai",
  "/classroomRevert/clean": "icon-tubiaozhizuomoban-",
  "bpmn:/#/projects": "icon-a-15tejialiuchengshenpi",
  "/mySelfContract": "icon-hetong",
  "/userContract": "icon-hetong1",
  "/classroomRevert/teacher": "icon-zhibanchaxun",
  "/classroomDoorLockManage": "icon-mensuo"
};

Object.keys(img).forEach(key => {
  img[key] = img[key];
});
export default {
  name: "workstation",
  data() {
    return {
      myMenuModules: []
    };
  },
  mounted() {
    this.getMyModuleMenus();
  },
  computed: {
    ...mapState(["userInfo"])
  },
  methods: {
    test() {
      let url = this.$utils.getFormUrlByKey("classroomReserve", { classroomId: 1 });
      location.href = url;
    },
    routerToMenu(to) {
      let externalLink = this.$utils.getExternalLink(to.url);
      if (externalLink) {
        location.href = externalLink;
        return;
      }
      this.$router.push({ path: to.url });
    },
    async getMyModuleMenus() {
      let ret = await this.$api.getMyModuleMenus({
        params: { type: "MOBILE" }
      });
      if (ret.code != SUCCESS_CODE) return;

      ret.data.forEach(item => {
        item.menus.forEach(it => {
          it.img = img[it.url];
        });
      });
      this.myMenuModules = ret.data;
    },
    async getWeixinToken() {
      await wechat.ready(["getNetworkType"]);

      wx.getNetworkType({
        success: function (res) {
          var networkType = res.networkType;
          console.log(networkType);
        }
      });
    }
  }
};
</script>
<style lang="less">
.menu-item {
  text-align: center;
}
.icon {
  width: 32px;
  height: 32px;
}
</style>
