import vheader from "./vheader.vue";
import itemCard from "./itemCard.vue";
import customPopup from "./customPopup.vue";

export default {
  install(Vue) {
    Vue.component("vheader", vheader);
    Vue.component("itemCard", itemCard);
    Vue.component("customPopup", customPopup);
  }
};
