<template>
  <div class="item-card">
    <slot name="header"
          v-if="showHeader">
      <div class="item-card-header">
        <div class="item-card-title">{{ title }}</div>
        <div class="item-card-status"
             v-if="status">{{ status }}</div>
        <slot name="status"
              class="item-card-status"
              v-if="!status"></slot>

      </div>
    </slot>
    <slot></slot>
    <slot name="footer"></slot>
  </div>
</template>

<script>
import "@/assets/less/components/itemCard.less";
export default {
  name: "ItemCard",
  props: {
    title: {
      type: String,
      default: "default title",
      required: true
    },
    status: {
      type: String,
      default: null
    },
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  }
};
</script>
