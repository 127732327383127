<template>
  <div class="page-index">
    <workstation v-if="active == 'workstation'"></workstation>
    <userInfo v-if="active == 'userInfo'"></userInfo>

    <van-tabbar v-model="active" @change="onTabBarChange">
      <van-tabbar-item name="workstation" icon="apps-o">工作台</van-tabbar-item>
      <van-tabbar-item name="userInfo" icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import "@/assets/less/views/index.less";
import workstation from "./tabBar/workstation.vue";
import userInfo from "./tabBar/userInfo.vue";
export default {
  name: "Index",
  data() {
    return {
      active: "workstation",
      myMenuModules: []
    };
  },
  methods: {
    onTabBarChange() {}
  },
  mounted() {
    this.$utils.getBpmnDiagramByName();
  },
  components: {
    userInfo,
    workstation
  }
};
</script>
