import Vue from "vue";
import VueRouter from "vue-router";
import Index from "@/views/index.vue";
import assets from "./modules/assets";
import building from "./modules/building";
import commodity from "./modules/commodity";
import contract from "./modules/contract";
Vue.use(VueRouter);

/**
 * hideReturn: 是否隐藏返回按钮
 * hideNavigation: 是否隐藏导航栏
 */
let routes = [
  ...assets,
  ...building,
  ...commodity,
  ...contract,
  {
    path: "/",
    name: "Index",
    component: Index,
    meta: {
      keepAlive: true,
      hideReturn: true,
      title: "首页"
    }
  },
  {
    path: "/applyByMe",
    name: "applyByMe",
    component: () => import("../views/approval/applyByMe.vue"),
    meta: {
      title: "我的申请"
    }
  },
  {
    path: "/approvalByMe",
    name: "approvalByMe",
    component: () => import("../views/approval/approvalByMe.vue"),
    meta: {
      title: "我的审核"
    }
  },
  {
    path: "/approvalByMeHistory",
    name: "approvalByMeHistory",
    component: () => import("../views/approval/approvalByMeHistory.vue"),
    meta: {
      title: "历史审核记录"
    }
  }
];

let main = [
  {
    path: "/403",
    name: "403",
    component: () => import("../views/error/403.vue"),
    meta: {
      title: "首页"
    }
  },

  {
    path: "/*",
    name: "404",
    component: () => import("../views/error/404.vue"),
    meta: {
      title: "首页"
    }
  }
];

export const allRouters = routes.concat(main);

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: allRouters,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
      }
      return { x: 0, y: to.meta.savedPosition || 0 };
    }
  }
});


router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  window.lastRoute = from.path;
  window.currentRoute = to.path;

  next();
});

export default router;
