<template>
  <div class="content">
    <h1>您好!{{ userInfo.userName }}</h1>
    <van-button plain type="danger" size="normal" @click="logout">退出</van-button>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { SUCCESS_CODE } from "../../apis/notification.js";
export default {
  name: "userInfo",
  data() {
    return {
      myMenuModules: []
    };
  },
  mounted() { },
  computed: {
    ...mapState(["userInfo"])
  },
  methods: {
    routerToMenu(menu) {
      this.$router.push({ url: menu.url });
    },
    logout() {
      this.$keycloak.logout();
    }
  }
};
</script>
