export default [
  // 我的课室审核
  {
    path: "/appointment",
    name: "classroomAppointmentRecord",
    component: () => import("../../views/classroom/classroomAppointmentRecord"),
    meta: {
      // title: "课室预约审核记录"
      title: "课室预约情况列表"
    }
  },
  // 我的预约记录 没对接好
  // {
  //   path: "/userAppointment",
  //   name: "classroomAppointmentAuditList",
  //   component: () => import("../../views/classroom/classroomAppointmentAuditList"),
  //   meta: {
  //     title: "课室预约审核"
  //   }
  // },
  // 课室列表
  {
    path: "/classroomList",
    name: "classroomList",
    component: () => import("../../views/classroom/classroomList"),
    meta: {
      title: "课室列表"
    }
  },
  // {
  //   path: "/classroomAppointment/:classroomId",
  //   name: "classroomAppointment",
  //   component: () => import("../../views/classroom/classroomAppointmentDetail"),
  //   meta: {
  //     title: "课室预约详情"
  //   }
  // }
  // 课室预约详情，不需要，用form
  // {
  //   path: "/classroomAppointmentDetail/:classroomId",
  //   name: "classroomAppointmentDetail",
  //   component: () => import("../../views/classroom/classroomAppointmentDetail"),
  //   meta: {
  //     title: "预约详情"
  //   }
  // }
  {
    path: "/userAppointment",
    name: "userAppointment",
    component: () => import("../../views/classroom/userAppointment.vue"),
    meta: {
      title: "课室预约情况记录"
    }
  },
  // 用户课室归还
  {
    path: "/classroomRevert/:personnel",
    name: "classroomRevert",
    beforeEnter: (to, from, next) => {
      if (to.params.personnel == "teacher") {
        to.meta.title = " 值班老师课室管理";
      } else if (to.params.personnel == "clean") {
        to.meta.title = " 清洁人员课室管理";
      } else {
        to.meta.title = "课室借用记录";
      }
      next();
    },
    component: () => import("../../views/classroom/classroomRevert"),
    props: true
  },
  {
    path: "/classroomRevertEdit/:personnel",
    name: "classroomRevertEdit",
    component: () => import("../../views/classroom/classroomRevertEdit"),
    props: true,
    meta: {
      title: "详情"
    }
  },
  // 课室信息详情
  {
    path: "/classroom",
    name: "classroom",
    component: () => import("../../views/classroom/classroom"),
    meta: {
      title: "课室信息详情"
    }
  },

  // {
  //   path: "/classroomManage",
  //   name: "classroomManage",
  //   component: () => import("../../views/classroom/classroomManage.vue"),
  //   meta: {
  //     title: "课室预约管理列表"
  //   }
  // },
  {
    path: "/classroomStateList",
    name: "classroomStateList",
    component: () => import("../../views/classroom/classroomStateList.vue"),
    meta: {
      title: "课室状态"
    }
  },
  // 课室状态详情
  {
    path: "/classroomStateEdit",
    name: "classroomStateEdit",
    component: () => import("../../views/classroom/classroomStateEdit.vue"),
    meta: {
      title: "详情"
    }
  },

  {
    path: "/classroomDoorLockManage",
    name: "classroomDoorLockManage",
    component: () => import("../../views/classroom/classroomDoorLockManage.vue"),
    meta: {
      title: "课室门锁管理"
    }
  },
  {
    path: "/doorLockManage",
    name: "doorLockManage",
    component: () => import("../../views/classroom/doorLockManage.vue"),
    meta: {
      title: "门锁管理"
    }
  },
  {
    path: "/doorLockDetail",
    name: "doorLockDetail",
    component: () => import("../../views/classroom/doorLockDetail.vue"),
    meta: {
      title: "门锁详情"
    }
  }
];
