<template>
  <van-sticky v-if="!hideNavigation"
              class="navigation">
    <van-nav-bar :title="title"
                 :left-arrow="!hideReturn"
                 @click-left="onClickLeft" />
  </van-sticky>
</template>

<script>
export default {
  name: "vheader",
  computed: {
    title() {
      return this.$route.meta.title;
    },
    hideNavigation() {
      return this.$route.meta.hideNavigation;
    },
    hideReturn() {
      return this.$route.meta.hideReturn;
    }
  },
  methods: {
    onClickLeft() {
      // 审批页的返回可能是推送直接进入，需要路由到首页
      if (this.$route.name == 'approvalByMe') {
        this.$router.push({ name: "Index" });
        return;
      }
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="less">
.navigation {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
}
</style>
