<template>
  <div id="main">
    <!-- 微信公众号有顶部，不需要vheader做顶部了，但是需要设置路由的meta.title属性 -->
    <vheader></vheader>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"
                   :key="$route.path"
                   :ref="$route.path" />
    </keep-alive>
    <router-view :ref="$route.path"
                 v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>

<style lang="less">
#main {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 46px;
  padding-bottom: 20px;
  min-height: 100vh;
  line-height: normal;
  background-color: #eee;
  box-sizing: border-box;
}
</style>
